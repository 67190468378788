<template>
  <div class="wrapper" ref="element_ref">
    <!--components/ViewControls.vue-->
    <div id="sidebar" class="sidebar">
      <ul class="top-icons-strip">
        <li v-for="(item, index) in menuItems" data-bs-toggle="tooltip" data-bs-placement="top" :title="item.title"
            :key="index" 
            :class="{ 'active-nav': isActive(index) }"
            @click="updatePreferences(index)">
          <i :class="item.icon" aria-hidden="true" ></i>
        </li>
      </ul>
      <span class="extra-margin-left">
        <PendingApproveRejectComponent :task="task"/>
        <span
          v-if="show_not_exist_in_current_space_ref.valueOf()"
          class="warning_not_exist_in_current_space"
          >
          Warning: This task does not exist in this workspace.
        </span>
      </span>
    </div>

    <div  class="agent_message_generating_executing" v-show="( (is_copilot_page) && (agent_message_state) && (agent_message_state.valueOf() != ''))">
      {{ agent_message_state }} 
      <div class="saving d-inline">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>

    <div class="flex1">
      <table class="copilot_toggle">
        <tbody>
          <tr>
            <td>agent: </td>
            <td>
              <div class="form-check form-switch " >
                <input class="form-check-input" type="checkbox" role="switch"  v-model="is_copilot_model" @change="toggle_copilot_mode">
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
const router = useRouter();
import PendingApproveRejectComponent from '../PendingApproveRejectComponent.vue';

const userPreferenceStore = useUserPreferenceStore();
const agent_message_state = useAgentMessageState();
let is_copilot_page = isCopilotPage();

let is_copilot_model = is_copilot_page;

const props = defineProps({
  task: {
    type: Object,
    required: true,
  }
});

const menuItems = [
  { title: "Show code", icon: "fal fa-code", action: "show_code"},
  { title: "Show description", icon: "fal fa-sharp fa-light fa-bars", action: "show_description"},
  { title: "Show i/o", icon: "fal fa-arrow-right-arrow-left", action: "show_io"},
  { title: "Show result", icon: "fa-kit fa-light-square-poll-horizontal-circle-play", action: "result"}
];

let preferences_ref = ref({});

// Load preferences from API
async function loadPreferences() {
  let preferences = userPreferenceStore.getUserPreference;
  preferences_ref.value = preferences;
}

const userInfoStore = useUserInfoStore();
let user_info = userInfoStore.getUserInfo

let is_copilot_page_state = useIsCopilotPageState();
const toggle_copilot_mode = () => {
  let url = window.location.href + '';
  url = url.replace(/[\?\&]agent=1/,'');
  if (is_copilot_model == true) {
    if (url.includes('?')) {
      url = url + '&agent=1'
    } else {
      url = url + '?agent=1'
    }
  }
  history.pushState({}, '', url);

  // is_copilot_page_state.value = ! is_copilot_page_state.value;
  // There is too much pain involved in removing the "empty task" that was added as the "prompt box".  Just reload the page for now.
  window.location.href = url;
}

loadPreferences();

let space_dropdown_state = useSpaceDropdownState();
if (hasPrivilege('job.view_result', space_dropdown_state.value)) {
  menuItems.push(
    { title: "Show execution history", icon: "fa-kit fa-light-rectangle-history-circle-play", action: "exec-history" }
  )
}

if (! user_info?.org?.toLowerCase().startsWith('trial')) {
  menuItems.push(
    { title: "Show comment", icon: "fal fa-comment", action: "chat" },
  )
}
const route = useRoute();
const isActive = (index) => {
  let menuItemAction = menuItems[index].action;
  let result = preferences_ref.value[menuItemAction] === true;
  return result;
};

const save_preferences = async () => {
    showWaitPage();
    await saveUserPreferences(preferences_ref.value);
    hideWaitPage();
}


const updatePreferences = async(index) => {
  let menuItemAction = menuItems[index].action;
  preferences_ref.value[menuItemAction] = !preferences_ref.value[menuItemAction];

  // There is an not quite opposite relationship between 'exec-history' and 'chat'
  if ((menuItemAction == 'exec-history') && (preferences_ref.value[menuItemAction] == true)){
    preferences_ref.value['chat'] = false;
  } else if ((menuItemAction == 'chat') && (preferences_ref.value[menuItemAction] == true)) {
    preferences_ref.value['exec-history'] = false;
  }

  //await save_preferences(); // do not call save_preferences for now because clicking on these views controls should not affect the preference page
  userPreferenceStore.setUserPreference(preferences_ref.value);
};

const somethingBasedOnPreferences = computed(() => {
  // Computed property logic
});

let element_ref = ref(null);
onMounted(() => {
  initializeTooltips(element_ref.value);
});

let show_not_exist_in_current_space_ref = ref(false);

const compute_show_not_exist_in_current_space = () => {
  let wsid = space_dropdown_state.value;
  let task = props.task;
  if (wsid) {
    if ((task.hasOwnProperty('workspace_ids')) && (task.workspace_ids.includes(wsid))) {
      show_not_exist_in_current_space_ref.value = false;
    } else if ((task.hasOwnProperty('workspace_status')) && (task.workspace_status.hasOwnProperty(wsid))) {
      show_not_exist_in_current_space_ref.value = false;
    } else {
      // This looks to be an old task that exists in the default space, but because the user select a workspace, 
      // technically, this task does not exist in the selected workspace, so we should display a warning.
      show_not_exist_in_current_space_ref.value = true;
    }
  } else {
    show_not_exist_in_current_space_ref.value = false;
  }
}
watch(space_dropdown_state, async (newVal, oldVal) => {
  compute_show_not_exist_in_current_space();
});

</script>

<style scoped>
 .sidebar{
   padding: 2px;
  }
  .wrapper {
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid #ccc;
    position: fixed;
    width: 100%;
    top: 48px;
    z-index: 995;
    background: white;
    opacity: 1;
  }
  #strip-menu {
    background-color: white !important;

    position: fixed;
    top: 48px;
    left: 50px;
    height: 33px;
    width: 100%;
    overflow: auto;
    border-bottom: 1px solid #ccc;
    z-index: 99;
  }
  
  .strip-menu{
    display: inline-flex;
  }
  .top-icons-strip {
    list-style: none;
    display: inline-flex ;
    margin-left: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0;
  }
  
  .top-icons-strip li:first-child {
    margin-left: 20px;
  }

  .top-icons-strip li {
    text-align: center;
    padding: 0px 5px;
    padding-top: 4px;
    cursor: pointer;
    margin-left: 10px;
    color: gray;
    height: 30px;
    width: 30px;
  }
  
  .top-icons-strip li:hover,
  .active-nav {
    background-color: #e5e5e5;
    color: black !important;
    border-radius: 50%;
  }
  .extra-margin-left {
    margin-left: 40px !important;
  }
  .warning_not_exist_in_current_space {
    color: orange;
    font-weight: bold;
  }

  .flex1 {
    flex: 1;
  }
  .copilot_toggle {
    float: right;
    margin-right: 350px;
  }

  .copilot_toggle td {
    padding-left: 10px;
  }

  .copilot_toggle td:first-child {
    padding-top: 2px;
  }

.switch {
    height: 22px;
    width: 50px;
}
.form-check-input {
    height: 1.5em;
    width: 3em;
    cursor: pointer;
}
.form-control:focus {
  box-shadow: none;
}
.form-check-input:focus {
    box-shadow: none;
}

  </style>
  
