export const updateJobDiv =  async (message, dayjs, msgtype, ws, existed) => {
    // The updateJobDiv composable works of a message (given a message), whereas the updateJobDivV2 composable is given a job_id, iteration, and a status object
    let job_id = message['job_id'];
    let element_id = 'job_comment_pane_' + job_id;
    let div_element = document.getElementById(element_id);

    // We only need to update the job div (iteration, status, start time, end time) if active_job_iteration is undefined 
    // or window['dagknows']['active_job_iteration'] == message.iter.  When the user is looking at a specific iteration, 
    // we do not want another iteration being executed to update this information in the job div.  Particularly, we do 
    // not want the iteration dropdown to change because this no longer match with what the user is looking at in the 
    // left hand side.
    if (
        true        
        /*
        (typeof(window['dagknows']['active_job_iteration']) == 'undefined') ||
        (window['dagknows']['active_job_iteration'] == message.iter)
        */
    ) {
        // update the iterations_select_element to show the corresponding iteration
        if (div_element) {
            let iterations_select_element = div_element.querySelector('select');
            if (iterations_select_element != null) {
                if (message.iter > iterations_select_element.value) {
                    iterations_select_element.value = message.iter;
                } else if (message.iter < iterations_select_element.value) {
                    // Is this happening as the user scroll up in the right panel?
                    // If I already see a later iteration, I don't think I need to update the job div
                    return;
                }
            }    
        }

        if ((! message.hasOwnProperty('job')) || (! message['job'].hasOwnProperty('status'))) {
            return;
        }

        if (div_element == null) {
            setTimeout(() => {
                updateJobDiv(message, dayjs, msgtype);
            }, 1000);
            return;
        }
        
        let is_single_iteration_job = isSingleIterationJob('message', message);
        let is_multi_iteration_job_finished  = false;
        if (is_single_iteration_job === false) {
            is_multi_iteration_job_finished = isMultiIterationJobCompleted('message', message);
        }

        // Update job / iteration status
        let status = message['job']['status']['state'];
        if ((typeof(existed) != 'undefined') && (existed)) {
            let messages_ref = window.dagknows?.messages_ref;
            let start_index = Math.max(messages_ref.value.length - 100, 0)
            messages_ref.value.slice(start_index).forEach((item) => {
                if (item.job_id === job_id) {
                    if (status == 'FINISHED') {
                        if ((is_single_iteration_job) || (is_multi_iteration_job_finished)) {
                            item['job']['status']['state'] = status;
                        }
                    }
                }
            })
        }
        //console.log(`UPDATE_JOB_DIV is_single_iteration_job: ${is_single_iteration_job}, is_multi_iteration_job_finished: ${is_multi_iteration_job_finished}, status: ${status}, message:`,message)

        if (! window.hasOwnProperty('dagknows')) {
            window['dagknows'] = {};
        }
        if (! window['dagknows'].hasOwnProperty('stopped_jobs')) {
            window['dagknows']['stopped_jobs'] = {};
        }
        
        let show_job_status = false;
        let show_stop_job_btn = false;

        if (window['dagknows']['stopped_jobs'].hasOwnProperty(job_id)) {
            show_job_status = true;
        } else if (is_single_iteration_job === true) {
            show_job_status = true;
            if ((['started'].includes(status.toLowerCase())) && (! window['dagknows']['stopped_jobs'].hasOwnProperty(job_id))) {
                // Status is 'started' and was not knowed as stopped
                show_stop_job_btn = true;
            }
            console.log("JOB STATUS:", status.toLowerCase())
        } else if (is_multi_iteration_job_finished === true) {
            // When you have multiple iterations then no need to show iteration status.  
            // But what should we show for the status field when the entire series is not yet completed?
            // For job that has a long schedule, such as those that has no end-time, should we even 
            // display a status field?
            // I hate the iteration status of 'READY'.  It seems confusing to the user (at least to me).
            show_job_status = true;
        } else if (! window['dagknows']['stopped_jobs'].hasOwnProperty(job_id)) {
            // Status is not completed, and was not knowned as stopped
            console.log(`JOB STATUS CHECK A.  JOB ID:${job_id}`)
            show_stop_job_btn = true;
        }

        if (message['job']['status'].hasOwnProperty('stop_issued_at')) {
            let good_effective_from = dayjs('2024-01-02T21:31:00.139462Z');
            let stop_obj = dayjs(message['job']['status']['stop_issued_at']);
            if (stop_obj.isAfter(good_effective_from)) {
                window['dagknows']['stopped_jobs'][job_id] = true;
            }    
        }
        
        if (window['dagknows']['stopped_jobs'].hasOwnProperty(job_id)) {
            div_element.querySelector('.job_status').innerText = 'STOPISSUED';
            show_job_status = true;
        } else if (['finished'].includes(status.toLowerCase())) {
            div_element.querySelector('.job_status').innerText = 'COMPLETED';
        } else {
            div_element.querySelector('.job_status').innerText = status;
        }

        if (show_job_status) {
            div_element.querySelector('.job_status_row').style.display = '';
        } else {
            div_element.querySelector('.job_status_row').style.display = 'none';
        }

        if (show_stop_job_btn) {
            // Double check to see if we are allowed to show the "Stop Job" button
            let space_dropdown_state = useSpaceDropdownState();
            if (! hasPrivilege('job.stop', space_dropdown_state.value)) {
                show_stop_job_btn = false;
            }
        }

        if (show_stop_job_btn) {
            if (div_element.querySelector('.stop_job_btn_container') != null) {
                div_element.querySelector('.stop_job_btn_container').style.display = '';
            }
        } else {
            if (div_element.querySelector('.stop_job_btn_container') != null) {
                div_element.querySelector('.stop_job_btn_container').style.display = 'none';
            }
        }
        
        if (msgtype == 'job_info') {
            // handle updating job start and end time
            if (is_single_iteration_job) {
                let started_at = isDkValidDate(message['job']['status']['started_at']) || 
                    isDkValidDate(message['job']['created_at']);

                let finished_at = isDkValidDate(message['job']['status']['finished_at']) || 
                    isDkValidDate(message['job']['status']['stop_issued_at']) || 
                    isDkValidDate(message['job']['updated_at']);
        
                if (['SUBMITTED','READY'].includes(message['job']['status']['state'])) {
                    finished_at = '';
                }

                formatTimeForDom(started_at, dayjs, div_element.querySelector('.job_start_time'));
                formatTimeForDom(finished_at, dayjs, div_element.querySelector('.job_end_time'));    

                if (message['job']['status']['state'] != 'SUBMITTED') {
                    $(div_element).find('.job_start_time_row').show();
                }
                if (['FINISHED','STOPISSUED'].includes(message['job']['status']['state'])) {
                    $(div_element).find('.job_end_time_row').show();
                }
            } else {
                if (div_element.querySelector('.job_start_time').innerText.trim() == 'TBD') {
                    let started_at = message['job']['created_at'];
                    formatTimeForDom(started_at, dayjs, div_element.querySelector('.job_start_time'));
                    $(div_element).find('.job_start_time_row').show();
                }

                if (is_multi_iteration_job_finished === true) {
                    let finished_at = isDkValidDate(message['job']['status']['finished_at']) || 
                        isDkValidDate(message['job']['status']['stop_issued_at']) || 
                        isDkValidDate(message['job']['updated_at']);
                    formatTimeForDom(finished_at, dayjs, div_element.querySelector('.job_end_time'));    
                    $(div_element).find('.job_end_time_row').show();    
                }
            }

            // handle updating iteration start and end time (which is only applicable to multi-iteration jobs)
            if (! is_single_iteration_job) {
                try {
                    let latest_iter = message.latest_iter;
                    if ((message['job'].hasOwnProperty('iter_statuses')) && (Object.keys(message.job.iter_statuses).length > 0) && (message.job.iter_statuses.hasOwnProperty(latest_iter))) {
                        let started_at = isDkValidDate(message['job']['iter_statuses'][latest_iter]['started_at']) ||
                            isDkValidDate(message['job']['iter_statuses'][latest_iter]['updated_at']);

                        formatTimeForDom(started_at, dayjs, div_element.querySelector('.start_time'));
        
                        let finished_at = isDkValidDate(message['job']['iter_statuses'][latest_iter]['finished_at']) ||
                            isDkValidDate(message['job']['iter_statuses'][latest_iter]['updated_at']);

                        formatTimeForDom(finished_at, dayjs, div_element.querySelector('.end_time'));    
    
                        $(div_element).find('.iteration_start_end_time_row').show();
                        $(div_element).find('.iteration_start_end_time_row').show();    
                    } else {
                        $(div_element).find('.iteration_start_end_time_row').hide();
                        $(div_element).find('.iteration_start_end_time_row').hide();
                    }
                } catch (e) {
                    console.warn(`getting a job that does not have iter_statuses or something.  Exception:`, e, 'message:', message);
                }
            }

            // Deal with the ABORTED stuff
            if (message?.job.aborted_at) {
                if (is_single_iteration_job) {
                    div_element.querySelector('.job_status').innerText = 'ABORTED';
                } else {
                    try {
                        let latest_iter = message.latest_iter;
                        if ((message['job'].hasOwnProperty('iter_statuses')) && (Object.keys(message.job.iter_statuses).length > 0) && (message.job.iter_statuses.hasOwnProperty(latest_iter))) {
                            let started_at = isDkValidDate(message['job']['iter_statuses'][latest_iter]['started_at']) ||
                                isDkValidDate(message['job']['iter_statuses'][latest_iter]['updated_at']);
                            let started_at_epoch = convertTimeToEpoch(started_at, dayjs);
            
                            let finished_at = isDkValidDate(message['job']['iter_statuses'][latest_iter]['finished_at']) ||
                                isDkValidDate(message['job']['iter_statuses'][latest_iter]['updated_at']);
                            let finished_at_epoch = convertTimeToEpoch(finished_at, dayjs);
    
                            let aborted_at_epoch = convertTimeToEpoch(message.job.aborted_at, dayjs);
                            if ((aborted_at_epoch >= started_at_epoch) && (aborted_at_epoch <= finished_at_epoch)) {
                                div_element.querySelector('.job_status').innerText = 'ABORTED';
                            }
                        }
                    } catch (e) {
                        console.warn(`getting a job that does not have iter_statuses or something.  Exception:`, e, 'message:', message);
                    }
                }
            }

        } else {
            // handle updating job start and end time
            if (is_single_iteration_job) {
                let started_at = message['started_at'];
                formatTimeForDom(started_at, dayjs, div_element.querySelector('.job_start_time'));

                let finished_at = message['finished_at'];
                formatTimeForDom(finished_at, dayjs, div_element.querySelector('.job_end_time'));

                $(div_element).find('.job_start_time_row').show();
                $(div_element).find('.job_end_time_row').show();
            } else {
                if (div_element.querySelector('.job_start_time').innerText.trim() == 'TBD') {
                    let started_at = message['job']['status']['started_at']
                    formatTimeForDom(started_at, dayjs, div_element.querySelector('.job_start_time'));
                    $(div_element).find('.job_start_time_row').show();    
                }

                if (is_multi_iteration_job_finished === true) {
                    let finished_at = message['job']['status']['finished_at'];
                    formatTimeForDom(finished_at, dayjs, div_element.querySelector('.job_end_time'));    
                    $(div_element).find('.job_end_time_row').show();    
                }
            }

            // handle updating iteration start and end time (which is only applicable to multi-iteration jobs)
            if (! is_single_iteration_job) {
                try {
                    let started_at = message['started_at'];
                    formatTimeForDom(started_at, dayjs, div_element.querySelector('.start_time'));

                    let finished_at = message['finished_at'];
                    formatTimeForDom(finished_at, dayjs, div_element.querySelector('.end_time'));

                    $(div_element).find('.iteration_start_end_time_row').show();
                    $(div_element).find('.iteration_start_end_time_row').show();

                } catch (e) {
                    console.warn(`Iteration Start and End time.  Exception (SSE or WebSocket):`, e);
                }
            }
        }

    }
}
