export const plotGraphs = (title, x_label, y_label, traces, inGraphDiv=null) => {
    var graphDiv = inGraphDiv;
    if (graphDiv == null){
      graphDiv = document.createElement('div');
    }
    
    const colors = [
       '#1f77b4',  // muted blue
       '#ff7f0e',  // safety orange
       '#2ca02c',  // cooked asparagus green
       '#d62728',  // brick red
       '#9467bd',  // muted purple
       '#8c564b',  // chestnut brown
       '#e377c2',  // raspberry yogurt pink
       '#7f7f7f',  // middle gray
       '#bcbd22',  // curry yellow-green
       '#17becf'   // blue-teal
    ];
    for (var t=0; t < traces.length; t++){
      if (traces[t]["type"] == 'pie' && !traces[t].hasOwnProperty('marker')) {
        traces[t]['marker'] = {};
        traces[t]['marker']['colors'] = colors;          
      }
    }
    var layout = {
        title: {
          text:title,
          font: {
            family: 'Courier New, monospace',
            size: 24
          },
          xref: 'paper',
          x: 0.05,
        },
        xaxis: {
          title: x_label
        },
        yaxis: {
          title: y_label
        }
      }

    //data = JSON.parse(traces)
    Plotly.newPlot(graphDiv, traces, layout).then(function(gd){
      /*
      This function is invoked as part of replayConv and therefore can potentially send this image over to Slack over and over again.
      Currently, we are setting the flag_enable_slack variable after the getSlackChannelsDropdown call, which seems to be invoked after 
      replayConv so we seems to be ok for now, but we need to come up with a better way to know if this function is being invoked as 
      part of replayConv.
      */
      /*
      if ((typeof(flag_enable_slack) != 'undefined') && (flag_enable_slack)) {
        Plotly.toImage(graphDiv,{format:'jpeg'}).then(function(url){
          var reqObj = new Object();
          reqObj.conv_id = current_conv_id;
          reqObj.image_format = 'jpeg';
          reqObj.image_data = url;
          sendARequest('/postImageToSlack', reqObj);
        })
      }
      */
    });
    return graphDiv;
}


export const updateTaskExecResultsView = (root_task_id, index_path, results, job_id, iteration, is_displayJob) => {
  if (typeof(window) != 'undefined') {
    if (! isAuthenticated()) {
      // If the user is not authenticaed, do not display execution result.
      return;
    }
  }

  let update = shouldUpdate(job_id, iteration, is_displayJob);

  if ( update ) {
    try {
      const plotelem = getIndexPathElem("plot_", root_task_id, index_path)
      const plots = results.extras.plots || []
      if (plots.length > 0) {
        const plot = plots[0]
          for (var i = 0 ; i < plot.traces.length;i++) {
            plot.traces[i]["type"] = plot.traces[i]["type"] || "lines"
          }
          plotGraphs(plot.title, plot.xlabel, plot.ylabel, plot.traces, plotelem)
      }

      const blocks = results?.extras?.blocks || [];
      renderBlocks(blocks, plotelem);

    
      let stdall = results.stdall || "";
      const exceptions = (results.exceptions || []).map(e =>  e.msg)
      if (exceptions.length > 0) {
        stdall += exceptions.join('\n');
      }
    
      const codeelem = getIndexPathElem("", root_task_id, index_path);
      if ((codeelem != null) && (stdall != '')) {
        codeelem.innerText = stdall;
        if (stdall.search(/\S+/) > -1) {
          codeelem.parentElement.classList.add('left_side_execution_result_with_padding_and_border');
          let hasVerticalScrollbar = codeelem.parentElement.scrollHeight > codeelem.parentElement.clientHeight;
          if (hasVerticalScrollbar) {
            codeelem.parentElement.nextSibling.style.display = 'block';
          } else {
            codeelem.parentElement.nextSibling.style.display = 'none';
          }
        } else {
          codeelem.parentElement.classList.remove('left_side_execution_result_with_padding_and_border');
        }
      }
      // Display the input and output parameters based on the information available as part of the results / message
      displayInputOutput(root_task_id, index_path, results)

      if (codeelem != null) {
        try {
          codeelem.parentElement.parentElement.parentElement.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
        } catch (ex) {
          console.warn(ex);
        }
      }
  
      let log_levels = [];
      let log_level = '';
    
      const logs = results.extras.logs || [];
      logs.forEach((log_object) => {
        log_levels.push(log_object.level.toLowerCase())
      })
    
      if (log_levels.includes('error')) {
        log_level = 'error';
      } else if (log_levels.includes('warning')) {
        log_level = 'warning'
      } else if (log_levels.includes('success')) {
        log_level = 'success'
      }
    
      if (log_level != '') {
        let css_class = 'left_side_execution_result_' + log_level;
        if (codeelem != null) {
          let task_el = findAncestorWithClass(codeelem, 'title_row')
          task_el.classList.add(css_class);
        }
      }
  
    } catch (e) {
      console.warn('An error happened inside updateTaskExecResultsView:', e);
    }  
  }
}
