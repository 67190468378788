export const shouldUpdate = (job_id, iteration, is_displayJob, job_state) => {
    /*
    When looking at the task details page, and receiving event for another job for the same task but I did not trigger 
    that job, should I be able to see live update?
    */

    if (typeof(is_displayJob) == 'undefined') {
      is_displayJob = false;
    }

    if (is_displayJob) {
      return true;
    }
   
    let execution_params_visible_state = useExecutionParamsVisibleState();
    if (execution_params_visible_state.value) {
      /*
      This is not a bug: If the user clicks on the "play" button, and we display the div that collect user inputs for the task,
      and if another user is executing the runbook, we do not want that to interfere.
      */
      return false;
    }

    let update = true;

    if (window['dagknows'].hasOwnProperty('active_job_id')) {
      if (window['dagknows']['active_job_id'] != job_id) {
        update = false;
      } else {
        if (window['dagknows'].hasOwnProperty('active_job_iteration')) {
          if (window['dagknows']['active_job_iteration'] == iteration) {
            update = true;
          } else if ((job_state.toUpperCase().includes('FINISH')) && (window['dagknows']['active_job_iteration'] == iteration -1)) {
            update = true;
          } else if (window['dagknows']['active_job_iteration'] != iteration) {
            update = false;
          }
        }
      }
    }
    return update;
}