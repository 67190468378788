<template>
    <div class="">
        <div class="editor-header">
            <div>
              <TaskTabContainer @tab-activated="updateActiveTask" />
            </div>
            <div class="view-control">
                <TaskEditorViewControl :key="activeTab.id" />
                <div v-if="activeTab.visible_section.code" class="code-editor-tool ">
                  <div class="">
                      <select 
                        class="code-type" 
                        v-model="task.script_type" 
                        @change="script_type_change" 
                        :disabled="props.readonly" 
                      >
                          <option value="command">Commands</option> 
                          <option value="python">Python</option>
                      </select>
                    </div>
                    <div>
                      <button class="check-syntax-btn" @click="gen_ai_suggest" >GenAI</button>
                    </div>
                    <div class="me-1" v-if="task.script_type == 'python'">
                      <button class="check-syntax-btn" @click="check_syntax">Check Syntax</button>
                    </div>
                   
                </div>
                <div class="save_and_exit_container">
                    <span class="save-icon">
                        <i class="fal fa-floppy-disk" aria-hidden="true"></i>
                        <span class="pointer" @click="save()"> Save</span>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;  
                    </span>
                    <span @click="close_code_editor()" class="pointer">Close editors</span>
                </div>
            </div>
        </div>
        <div class="editor-area">
            <div class="w-full pt-1 mb-2" v-if="activeTab.visible_section.title">
              <input name="title" v-model="task.title" class="w-full">
            </div>
            <div ref="create_edit_view_ref create_edit_view_task mt-10">
              <ClientOnly>
              <QuillEditor v-if="activeTab.visible_section.description"
                :placeholder="(removeHtml(task.description,'strict')) == '' ? 'Description' : ''" 
                v-model:content="task.description" 
                contentType="html" 
                theme="snow" 
                @copy="ignore_command_c_combo"
                class="quill_editor class_des" 
                :toolbar="toolbarOptions"/>
              </ClientOnly>

                <div v-if="activeTab.visible_section.tag" class="mt-10">
                  <TaskTagInput @add:tag="onAddNewTag" @remove:tag="onRemoveTag"/>
                </div>

              <div v-if="activeTab.visible_section.io" class="mt-10">
                <ParamsList v-if="task.input_params" :list="task.input_params" header="inputs" type="input" :readonly="props.readonly"></ParamsList>
                <ParamsList v-if="task.output_params" :list="task.output_params" header="outputs" type="output" :readonly="props.readonly"></ParamsList>
              </div>
             
              <div v-if="task.script_type === 'command' && !genAiOnly && activeTab.visible_section.code" :class="{'command_box position-relative codemirror_container': true, 'our_codemirror_readonly': props.readonly}">
                <div style="position:relative">
                  <codemirror 
                    v-model="task.full_command" 
                    :style="{}" 
                    :autofocus="true" 
                    :indent-with-tab="true" 
                    :tab-size="2" 
                    :extensions="command_extensions" 
                    @ready="handleReady" 
                    placeholder="Command" 
                    :disabled="props.readonly" 
                    @copy="ignore_codemirror_copy"
                    :key="'code_mirror_force_update_' + code_mirror_force_update_ref.valueOf()"
                  />
                </div>
              </div>

              <div v-if="task.script_type == 'python' && activeTab.visible_section.code" class="mt-10" :class="{'code_box show_line_numbers codemirror_container': true, 'our_codemirror_readonly': props.readonly}">
                <div style="position:relative">
                    <codemirror
                      v-if="isCodeReady"
                      v-model="task.script.code" 
                      style="min-height: 430px; overflow: auto" 
                      :style="{ height: editorHeight }"
                      :autofocus="true" 
                      :indent-with-tab="true" 
                      :tab-size="2" 
                      placeholder="" 
                      @change="codeChanged"
                      :extensions="code_extensions" 
                      @ready="handleReady"
                      :disabled="props.readonly" 
                      @copy="ignore_codemirror_copy"
                      :key="'code_mirror_force_update_' + code_mirror_force_update_ref.valueOf()"
                    />
                </div>
                <pre :id="code_mirror_syntax_check_pre_id" style="display:none" class="code_mirror_syntax_check_result"></pre>
              </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, nextTick, onMounted } from "vue";
import { Codemirror } from "vue-codemirror";
import { python } from "@codemirror/lang-python";
import { oneDark } from "@codemirror/theme-one-dark";
import { basicSetup } from "codemirror";

import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const props = defineProps({
//   item: {
//     type: Object,
//     required: true,
//   },
  taskCache: {
    type: Object,
  },
//   genAiOnly:{
//     type: Boolean,
//     required: true,
//   },
  readonly: {
    type: Boolean,
    required: true,
  },
  parentTaskId: {
    type: String,
    required: false,
    default: "",
  },
  index: {
    type: Number,
  },
  is_new: {
    type: Boolean,
    default: false,
  },
//   parents: {
//     type: Array,
//   },
//   step_number: {
//     type: String,
//     required: true
//   },
  // parent: {
  //   type: Object
  // }
});
const emits = defineEmits(["notify-saved"]);


const taskStore = useTaskStore();
const task = ref({});
let tag_list_ref = ref([]);
const finalTags = ref([]);
let input_params_ref = ref([]);
let output_params_ref = ref([]);
let script_type_ref = ref(null);
const taskCache = props.taskCache;



const isCodeReady = ref(false);
const activeTab = computed( () =>{
  return taskStore.getActiveTab
})

const tasks = computed( () =>{
  return taskStore.getTasks
})

const editorHeight = ref('calc(100vh - 140px)');
const updateEditorHeight = () => {
  editorHeight.value = `calc(${window.innerHeight}px - 160px)`;
};

let toolbarOptions = [
  [{ 'font': fontNames }],
  [{ size: ['small', false, 'large', 'huge'] }], 
  ['bold', 'italic', 'underline', 'strike'], 
  ['blockquote', 'code-block'], 
  [{ list: 'ordered' }, { list: 'bullet' }], 
  [{ header: [1, 2, 3, 4, 5, 6, false] }], 
  [{ color: [] }, { background: [] }], 
  ['link'], ['image'], ['clean']
];

const code_mirror_force_update_ref = ref(0);

const close_code_editor = () => {
  taskStore.setCodeEditorVisibility(false);
  taskStore.setChatVisible(true)

  taskStore.removeAllTask()
  taskStore.setAllTabs([])
  taskStore.resetActiveTab()
  taskStore.setTaskSaveFromTab('close-tab')
}

const updateActiveTask = (tabId)=> {
  activeTab.id = tabId
  tasks.value.forEach( (item) => {
    if(item.id === tabId){
      task.value = item
      setInputParamsRef(item)
      setOutputParamsRef(item)
    }
  })

  updateEditorHeight();
  isCodeReady.value = true
}


const quill_ready = (event) => {
  let ancestor = findAncestorWithClass(event.root, 'create_edit_view_task')
  let toolbar = ancestor.querySelector('.ql-toolbar');
  let selectElement = toolbar.querySelector('select.ql-font');
  fontList.forEach(function(font) {
    let fontName = getFontName(font);
    // fontName is the lower-cased, and font is the display name
    let options = selectElement.options;
    let len = options.length;
    let added = false;
    for (let i = 0; i < len; i++) {
      let option = options[i];
      if (fontName == option.value) {
        added = true;
      }
    }
    if (added == false) {
      let option = document.createElement('option');
      option.value = fontName;
      option.text = font;
      selectElement.add(option);
    }
  });
}

const codeChanged = () =>{
  let tabs = taskStore.getCodeEditorTabs;
  let updatedTabs = tabs.map(tab => ({
    ...tab,
    hasUnSavedChange: tab.id === task.value.id ? true : tab.hasUnSavedChange
  }));

  taskStore.setAllTabs(updatedTabs);
}

const setEditorCode = () => {
  tasks.value.forEach( (item) => {
    if(item.id === taskStore.getActiveTab.id){
      task.value = item
      setInputParamsRef(item)
      setOutputParamsRef(item)
    }
  })
  isCodeReady.value = true
}

const handleKeyPress = (event) => {
  //console.log("🚀 ~ handleKeyPress ~ event:", event.key, ", code: ", event.code, ", ctrlKey=",event.ctrlKey,)
  if (event.ctrlKey && event.key === 's') { // Example: Ctrl + S
    event.preventDefault(); // Prevent default action, if necessary
    // Your code for Ctrl + S
    console.log('Ctrl + S is pressed!');
    save()
  }
}

const gen_ai_suggest = async (event) => {
  
  await showWaitPage();
  let url_prefix = community_url;
  if (isDagknowsCommunityGenAIEnabled() === false) {
    url_prefix = '';
  }

  let response = await suggestActions(url_prefix, task.value?.title, task.description, task.script_type);
  hideWaitPage();
  let suggested_task = response.task;
  task.value.title = suggested_task.title;
  task.value.description = suggested_task.description;
  if (task.value?.script_type == 'command') {
    task.value.full_command = suggested_task.commands.join("\n");
  } else {
    task.value.script.code = suggested_task.script.code.script;
  }
  input_params_ref.value = task.value?.input_params;
  output_params_ref.value = task.value?.output_params;

  input_params_ref.value.forEach((param) => {
    if ((! param.hasOwnProperty('value')) && (param.hasOwnProperty('default_value'))) {
      param['value'] = param['default_value'];
    }
  });

  if (suggested_task.hasOwnProperty('tags')) {
    tag_list_ref.value = suggested_task.tags;
    let tags = [];
    tag_list_ref.value.forEach((e) => {
      tags.push({'name': e});
    });
    finalTags.value = tags;
  }

  code_mirror_force_update_ref.value = code_mirror_force_update_ref.value + 1;
}
const script_type_change = () => {
  script_type_ref.value = task.script_type;
};

const setInputParamsRef = (task) => {

  input_params_ref.value = task?.input_params;

  input_params_ref.value.forEach((param) => {
    if ((! param.hasOwnProperty('value')) && (param.hasOwnProperty('default_value'))) {
      param['value'] = param['default_value'];
    }
  });

  input_params_ref.value.forEach((param) => {
    if (task.script_type == 'command') {
      if ((! param.hasOwnProperty('param_type')) || (typeof(param.param_type) == 'undefined') || (param.param_type == '')) {
        param.param_type = 'String';
      }
    }
  });
}
const setOutputParamsRef = (task)=>{
  output_params_ref.value = task?.output_params;
  script_type_ref.value = task?.script_type;
}

const save = async () => {

  console.log("saving...")
  let tabs = taskStore.getCodeEditorTabs;
  let updatedTabs = tabs.map(tab => ({
    ...tab,
    hasUnSavedChange: tab.id === task.value.id ? false : tab.hasUnSavedChange
  }));

  taskStore.setAllTabs(updatedTabs);
  save_main()
}

const save_main = async () => {
 
  // console.log("🚀 ~ constsave_main= ~ currentTask:", task.value?.tags)
  
  if (task.value?.title == "") {
    dkAlert("Title is required.");
    return;
  }

  let cloned_task = cloneTask(task.value);

  let tags = [];
  
  /*
  Not able to remove a tag, because here we are adding it back to finalTags for some reason.
  if(finalTags.value?.length == 0 && task.value?.tags?.length > 0){
    let tag_list_ref = task.value?.tags;
    let tags = [];
    tag_list_ref.forEach((e) => {
      tags.push({'name': e});
    });
    finalTags.value = tags;
  }
  */

  finalTags.value?.forEach((e) => {
    tags.push(e.name);
  });
  
  cloned_task["tags"] = tags;

  let subtask_info = null;
  const sub_task_index = props.index;
  if (!props.parentTaskId || props.is_new) {
    // Editing a standalone task (can be a child or a parent but on its own page)
    // Or adding a new child task
    let input_params = [];
    input_params_ref.value.forEach((e) => {
      input_params.push({
        name: e.name,
        default_value: (props.step_number == '') ? e.value : '',
        assignment: (props.step_number != '') ? e.value : '',
        param_type: e.param_type,
        required: e.required
      });
    });

    let output_params = [];
    output_params_ref.value.forEach((e) => {
      output_params.push({ name: e.name, assignment: e.value, param_type: e.param_type });
    });

    cloned_task["input_params"] = input_params;
    cloned_task["output_params"] = output_params;

  } else {
    // Editting a task that has parent
    subtask_info = {
      taskid: cloned_task["id"],
      inputs: {},
      outputs: {},
    };
    input_params_ref.value.forEach((e) => {
      subtask_info["inputs"][e.name] = e.value;
    });
    output_params_ref.value.forEach((e) => {
      subtask_info["outputs"][e.name] = e.value;
    });
  }

  if (!cloned_task["id"]) {
    const new_task = await createTask(cloned_task, taskCache);
    if (props.parentTaskId != '') {
      let parentTask = taskCache[props.parentTaskId];
      if (parentTask.hasOwnProperty('approved_permissions')) {
        const result = await addUsersToTask(new_task.id, parentTask['approved_permissions']);
      }
    }

    subtask_info = {
      taskid: new_task.id,
      inputs: {},
      outputs: {},
    };
    input_params_ref.value.forEach((e) => {
      subtask_info["inputs"][e.name] = e.value;
    });
    output_params_ref.value.forEach((e) => {
      subtask_info["outputs"][e.name] = e.value;
    });
    if (subtask_info) {
      if (props.parentTaskId) {
        const sub_task_ops = [
          {
            optype: "set",
            index: sub_task_index,
            new_sub_task: subtask_info,
          },
        ];
        const updated = await updateTask(props.parentTaskId, taskCache, null, null, sub_task_ops);
        // console.log("Updated 1: ", updated);
      }
    }
    emits("notify-saved", new_task.id);
    taskStore.setTaskSaveFromTab(new_task.id)
  } else {
    const update_mask = Object.keys(cloned_task);
    await updateTask(cloned_task.id, taskCache, cloned_task, update_mask);
    if (subtask_info) {
      // const parentTask = await readTask(props.parentTaskId, {});
      const updated = await updateTask(props.parentTaskId, taskCache, null, null, [
        {
          optype: "set",
          index: sub_task_index,
          new_sub_task: subtask_info,
        },
      ]);
      // console.log("Updated: ", updated);
    }
    emits("notify-saved", task.value?.id);
    taskStore.setTaskSaveFromTab(task.value?.id)
  }
  
};

const onAddNewTag = tags => {
  finalTags.value = tags
  console.log("🚀 ~ onAddNewTag ~ finalTags.value:", finalTags.value)
}

const onRemoveTag = tags => {
  finalTags.value = tags;
}

const python_check_syntax = () => {
  currentPre = document.getElementById(code_mirror_syntax_check_pre_id);
  currentPre.innerText = '';
  currentPre.style.display = 'block';
  let prog = task.script.code;
  Sk.pre = code_mirror_syntax_check_pre_id;
  Sk.configure({ output: outputFunction, read: builtinRead, __future__: Sk.python3 });
  var myPromise = Sk.misceval.asyncToPromise(function() {
      return Sk.importMainWithBody("<stdin>", false, prog, true);
  });

  myPromise.then(function(mod) {
    // $errorContainer.hide();
    // $errorContainer.html("");
    currentPre.classList.add("class_no_sytnax_error");
    currentPre.innerText = "No syntax errors.";
    currentPre.style.display = 'block';
    hide_no_syntax_error_message();
  },function(err) {
      var errstr = err.toString();
      if (errstr.trim().startsWith("SyntaxError")){
        console.log(errstr);
        currentPre.innerText = errstr;
        currentPre.style.display = 'block';
      } else {
        currentPre.classList.add("class_no_sytnax_error");
        currentPre.innerText = "No syntax errors.";
        currentPre.style.display = '';
        hide_no_syntax_error_message();
      }
  });
}

const check_syntax = () => {
  if (task.value?.script_type == 'python') {
    python_check_syntax();
  }
}

watchEffect(() => {
  //console.log("🚀 ~ watchEffect ~ taskStore.task:", taskStore.getActiveTab.id);
  setEditorCode()
});

const ignore_command_c_combo = (event) => {
    // This empty method is being used to ignore the Mac Command+c combination.
    // Somehow this combination is firing the 'copy' DOM event and that got 
    // mixed up with our 'copy' event somehow.
    event.stopPropagation();

    // If we call event.preventDefault(), it will not copy the selected text to the clipboard
    // which is not what we want.
    // Inside the CreateEditViewTask.vue, we have the ignore_codemirror_copy function.  There 
    
    //event.preventDefault();
}

// Codemirror EditorView instance ref
const view = shallowRef();
const handleReady = (payload) => {
  view.value = payload.view;
};

const ignore_codemirror_copy = (event) => {
  // This empty method is being used 
  event.stopPropagation();
  event.preventDefault();
}

const command_extensions = [python(), oneDark];
const code_extensions = [python(), basicSetup, oneDark];


onMounted(() => {
  tasks.value.forEach( (item) => {
    if(item.is_active) {
      task.value = item
      setInputParamsRef(item)
      setOutputParamsRef(item)
    }
  })
  window.addEventListener('resize', updateEditorHeight);
  document.addEventListener('keydown', handleKeyPress);
})

onUnmounted(() => {
  window.removeEventListener('resize', updateEditorHeight);
  document.removeEventListener('keydown', handleKeyPress);
  close_code_editor()
});

</script>

<style scoped>
.mt-10{
  margin-top: 10px;
}
.editor-area{
  padding-left: 5px;
}
.view-control{
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    margin-bottom: 5px;
    padding: 6px 15px 0px 15px;
    font-size: 12px;
}

.save_and_exit_container{
    color: #0d6efd;
    font-size: 12px;
}
.tab-active-bg{
    background-color: #fff;
}
.tab-inactive-bg{
    background-color: #ccc;
}
.pointer{
  cursor: pointer;
}
.code-editor-tool{
  display: flex;
}
.code-type{
  margin-right: 5px;
  height: 22px;
  border: 1px solid #ccc;
}
.check-syntax-btn{
  background-color: #fff;
  border: 1px solid #ccc;
}
.w-full{
  width: 100%;
}
.save-icon{
  font-size: 14px;
}
</style>