export const dealWithStopThisJobNotGoingAway = async (job_id) => {
    //console.log("dealWithStopThisJobNotGoingAway INVOKED");
    const agent_message_state = useAgentMessageState();
    const route = useRoute();
    if (route.path.includes("/task")) {
        let result = await getJob(job_id, '');
        if (result.job.status.state == 'FINISHED') {
            agent_message_state.value = "";
            let div_id = 'job_comment_pane_' + job_id;
            let job_card_div = document.getElementById(div_id);
            if (job_card_div != null) {
                job_card_div.querySelector('.stop_job_btn_container').style.display = 'none'
            }
        }    
    }
}